import useSWR from 'swr';
import {v4 as uuidv4} from 'uuid';
import axios from 'axios';

export function setCredentials(username, password) {
	localStorage.setItem('apiUsername', username);
	localStorage.setItem('apiPassword', password);
}

function getCredentials() {
	return {
	  username: localStorage.getItem('apiUsername'),
	  password: localStorage.getItem('apiPassword')
	};
}

export function updateKunden(mutate) {
  axios.get(window.ACDSHOP_API_URL + '/api/egetraenke/kunden', {auth: getCredentials()})
    .then(res => {
	  const kunden = res.data.sort((a,b) => a.name > b.name ? 1 : -1);
	  localStorage.setItem('kunden', JSON.stringify(kunden));
		
      mutate('localStorage/kunden');
  });
}
  
function useKunden() {
  const getKundenFromLocalStorage = () => {
    const kunden = JSON.parse(localStorage.getItem('kunden'));
	if(kunden == null) {
	  return [];
    }
  
    return kunden;
  };
  
  const {data, isLoading} = useSWR(`localStorage/kunden`, () => Promise.resolve(getKundenFromLocalStorage()));

  return isLoading ? [] : data;
}

export function useKundengruppen() {
  const kunden = useKunden();
  
  return Array.from(new Set(kunden.map(k => k.name[0]))).sort();
}

export function useGruppeKunden(gruppe) {
  const kunden = useKunden();
  
  return Array.from(kunden.filter(k => k.name[0] === gruppe)).sort();
}

export function useKunde(kundeId) {
  const kunden = useKunden();
  
  return kunden.filter(k => k.id === kundeId)[0];
}

export function updateProdukte(mutate) {
  axios.get(window.ACDSHOP_API_URL + '/api/egetraenke/produkte', {auth: getCredentials()})
    .then(res => {
	  const produkte = res.data;
	  localStorage.setItem('produkte', JSON.stringify(produkte));
		
	  mutate('localStorage/produkte');
  });
}
  
export function useProdukte() {
  const getProdukteFromLocalStorage = () => {
    const produkte = JSON.parse(localStorage.getItem('produkte'));
	if(produkte == null) {
	  return [];
    }
  
    return produkte;
  };
  
  const {data, isLoading} = useSWR(`localStorage/produkte`, () => Promise.resolve(getProdukteFromLocalStorage()));

  return isLoading ? [] : data;
}

export function useOffeneEinkaeufe(kundeId) {
  const getOffeneEinkaeufe = () => {
    const einkaeufe = JSON.parse(localStorage.getItem('einkaeufe'));
	if(einkaeufe == null) {
	  return [];
    }
  
    return einkaeufe.filter(e => e.kundeId == kundeId);
  }  
	
  var {data, isLoading} = useSWR(`localStorage/offeneEinkaeufe`, () => Promise.resolve(getOffeneEinkaeufe()));
  
  return isLoading ? [] : data;
}

export function storeEinkauf(kunde, produkt, anzahl) {
  	var einkaeufe = JSON.parse(localStorage.getItem('einkaeufe'));
	if(einkaeufe == null) {
	  einkaeufe = [];
	}
	
	const einkauf = {
	  id: uuidv4(),
      timestamp: new Date(),
      kundeId: kunde.id,
	  produktName: produkt.name,
	  anzahl: anzahl,
	  preis: anzahl * produkt.preis
	}
	
	einkaeufe.push(einkauf);
	
	localStorage.setItem('einkaeufe', JSON.stringify(einkaeufe));
}

export function deleteEinkauf(einkauf) {	
	var einkaeufe = JSON.parse(localStorage.getItem('einkaeufe'));
	if(einkaeufe == null) {
	  einkaeufe = [];
	}
	
	einkaeufe = einkaeufe.filter(e => e.id != einkauf.id);
	
	localStorage.setItem('einkaeufe', JSON.stringify(einkaeufe));
}

export function postEinkaeufe() {
  const now = new Date();
  var uuidGesendet = [];
  var promises = [];
	
  var einkaeufe = JSON.parse(localStorage.getItem('einkaeufe'));
  var einkaeufeSenden = einkaeufe?.filter(e => now - new Date(e.timestamp) > window.ACDSHOP_POST_EINKAUF_DELAY);
	  
  if(einkaeufeSenden?.length > 0) {
	einkaeufeSenden.forEach(e => {
	  promises.push(axios.post(window.ACDSHOP_API_URL + '/api/egetraenke/einkauf', e, {auth: getCredentials()})
	    .then(res => uuidGesendet.push(e.id)));	  
	  
	  if(promises.length > 0) {
	    Promise.all(promises).then(() => {
	      const einkaeufeNeu = einkaeufe.filter(e => !uuidGesendet.includes(e.id));
	      localStorage.setItem('einkaeufe', JSON.stringify(einkaeufeNeu));	  
	    });	  
	  }
	});
  }
}
import React, {useState, useEffect} from 'react';
import './App.scss';
import {useParams, useNavigate} from "react-router-dom";
import {useKundengruppen, useGruppeKunden, useKunde, useProdukte, useOffeneEinkaeufe, storeEinkauf, deleteEinkauf, updateKunden, updateProdukte, setCredentials} from './api/DataAccess.js';
import {toast} from 'react-toastify';
import {useSWRConfig} from 'swr';

export function Admin() {
	const [username, setUsername] = useState('');
	const [password, setPassword] = useState('');
	const { mutate } = useSWRConfig();
	const navigate = useNavigate();
	
	const onSave = () => {
	  setCredentials(username, password);
		
	  updateKunden(mutate);
	  updateProdukte(mutate);
	  
	  navigate('/');
	};
	
	return (
	  <div className="row">
        <div className="col-3"></div>
	      <div className="col-2">
	        <input 
	          type="text"  
	          value={username} 
	          onChange={e => setUsername(e.target.value)}
	          className="form-control form-control-lg"
	          placeholder="Benutzername" />
	      </div>
	      <div className="col-2">
	        <input 
	          type="text"  
	          value={password} 
	          onChange={e => setPassword(e.target.value)}
	          className="form-control form-control-lg"
	          placeholder="Passwort" />
	      </div>
	      <div className="col-2">
	        <button onClick={onSave} className="form-control form-control-lg">Speichern</button>
	      </div>
	      <div className="col-3"></div>
      </div>
	);
}

export function GruppenListe() {
  const kundengruppen = useKundengruppen();
  const navigate = useNavigate();

  return (
    <div className="row">
      {kundengruppen.map(g => (
	    <div key={g} className="d-grid col-2 pb-5">
	      <button onClick={() => navigate(`/gruppe/${g}`)} className="btn btn-outline-primary d-flex justify-content-center">{g}</button>
	    </div>))}
    </div>
  );
}

export function KundenListe() {
  const {gruppeId} = useParams();
  const kunden = useGruppeKunden(gruppeId);
  const navigate = useNavigate();
  
  useReturnHome();

  return (
    <div className="row">
	  {kunden.map(k => (
		<div key={k.id} className="d-grid col-4 pb-5">
		  <button onClick={() => navigate(`/kunde/${k.id}/einkauf`)} className="btn btn-outline-primary d-flex justify-content-center">{k.name}</button>
		</div>))}
	</div>
  );
}

export function Einkauf() {
  const {kundeId} = useParams();
  const kunde = useKunde(kundeId);
  const produkte = useProdukte();
  const [pin, setPin] = useState('');
  useReturnHome();
  
  
  if(pin == kunde.pin || kunde.pin == null) {
	return (
	<>
      <div className="row">
	      {produkte?.map(p => (
	        <div className="col-6 pb-3 bigfont" key={p.id}>
	      	  <Produkt produkt={p} kunde={kunde} />
		    </div>
	      ))}
	    </div>
	  </>
    );
  } else {
	return (
	  <>
	    <div className="row">
	      <div className="col-5"></div>
	      <div className="col-2">
	        <input 
	          type="text" 
	          pattern="[0-9]*" 
	          value={pin} 
	          onChange={e => setPin(e.target.value)} 
	          autoFocus={true}
	          className="form-control form-control-lg"
	          style={{'-webkit-text-security': 'circle'}}
	          placeholder="PIN" />
	      </div>
	      <div className="col-5"></div>
	    </div>
	  </>	
	);
  }
  
  function Produkt(props) {
	const [anzahl, setAnzahl] = useState(1);
    const navigate = useNavigate();	
	
	const produkt = props.produkt;
	const kunde = props.kunde;
	const decrement = () => {
	  setAnzahl(anzahl > 1 ? anzahl - 1 : anzahl);
	};
	const increment = () => {
	  setAnzahl( anzahl < 10 ? anzahl + 1 : anzahl);
	};
	
	const einkaufen = () => {
	  storeEinkauf(kunde, produkt, anzahl);
	  
	  toast('Einkauf wurde gespeichert');
	  navigate('/');
	}
	
    return (
	  <>
	    <button onClick={einkaufen} className="btn btn-outline-primary me-2">{produkt.name} - {new Intl.NumberFormat('de-DE', {style: 'currency', currency: 'EUR'}).format(produkt.preis)}</button> <span><a onClick={decrement} className="bigfont bi bi-dash-circle"></a> {anzahl} <a onClick={increment} className="bigfont bi bi-plus-circle"></a></span>
	  </>
	);
  }
}

export function EinkaufUebersicht() {
  const { mutate } = useSWRConfig();
  const {kundeId} = useParams();
  const {kunde, isLoading, isError} = useKunde(kundeId);
  const einkaeufe = useOffeneEinkaeufe(kundeId);
  
  useReturnHome();
  
  const onDelete = (e) => {
	deleteEinkauf(e);
	mutate('localStorage/offeneEinkaeufe');
  };
  
  return (
    <>
	  <div className="row bigfont">
	    <h2>Offene Einkäufe</h2>
	    <table className="table table-striped">
          <thead>
            <tr>
              <th scope="col">#</th>
              <th scope="col">Produkt</th>
              <th scope="col">Zeit</th>
              <th scope="col">Anzahl</th>
              <th scope="col">Preis</th>
              <th scope="col">Aktion</th>
            </tr>
          </thead>
          <tbody>
            {einkaeufe?.map((e, i) => (
              <tr key={e.id}>
                <th scope="row">{i + 1}</th>
                <td>{e.produktName}</td>
                <td>{new Date(e.timestamp).toLocaleDateString("de-DE")} {new Date(e.timestamp).toLocaleTimeString("de-DE")}</td>
                <td>{e.anzahl}</td>
                <td>{new Intl.NumberFormat('de-DE', {style: 'currency', currency: 'EUR'}).format(e.preis)}</td>
                <td><button onClick={() => onDelete(e)} className="btn btn-outline-primary bi bi-trash"></button></td>
              </tr>
            ))}
          </tbody>
        </table>
	  </div>
	</>
  );
}

function useReturnHome() {
	const navigate = useNavigate();	
	
	useEffect(() => {
		const timeout = setTimeout(() => navigate('/'), window.ACDSHOP_HOMESCREEN_TIMEOUT);
		
		return () => clearTimeout(timeout);
	});
}
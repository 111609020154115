import React, {useEffect, useContext} from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import 'react-toastify/dist/ReactToastify.css';
import {GruppenListe, KundenListe, Einkauf, EinkaufUebersicht, Admin} from './Shopping';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
import {createBrowserRouter, RouterProvider, Link, Outlet, useNavigate, useParams} from 'react-router-dom';
import {ToastContainer} from 'react-toastify';
import {useSWRConfig} from 'swr';
import {postEinkaeufe, updateKunden, useKunde, updateProdukte} from './api/DataAccess.js';

const router = createBrowserRouter([
  {
    path: "/",
	element: <Root />,
    children: [
	  {
	    index: true, 
	    element: <GruppenListe />
	  },
	  {
        path: "/admin",
        element: <Admin />
      },
	  {
        path: "/gruppe/:gruppeId",
        element: <KundenListe />
      },
      {
	    path: "/kunde/:kundeId/einkauf",
        element: <Einkauf />	
      },
      {
	    path: "/kunde/:kundeId/uebersicht",
        element: <EinkaufUebersicht />	
      }
	]
  }
]);

function Root() {
  const navigate = useNavigate();
  const {kundeId} = useParams();
  const kunde = useKunde(kundeId);
	
  return (
	<>      
      <nav className="navbar navbar-light bg-light mb-3">
        <div className="container-fluid justify-content-start">
	      <span className="navbar-brand">
	        <img src="/logo.png" width="50" height="50"></img>
	      </span>
          
          <a onClick={() => navigate("/")} className="bi bi-house me-4" style={{fontSize: "2rem"}}></a>
		  {kunde && 
		    <>
		      <a onClick={() => navigate(`/kunde/${kunde?.id}/uebersicht`)} className="bi bi-cart-x me-4" style={{fontSize: "2rem"}}></a>
		      <span className="bigfont me-4">{kunde?.name}</span>
		    </>
		  }  
	    </div>
      </nav>
      
      <div className="container-fluid">
	    <Outlet />
	  </div>
	</>
  );
}

function App() {
  const { mutate } = useSWRConfig();
  
  useEffect(() => {
    const interval = setInterval(() => {
      postEinkaeufe();
    }, window.ACDSHOP_POST_EINKAEUFE_INTERVAL);
	
    return () => clearInterval(interval);
  }, []);
  
  useEffect(() => {
    const interval = setInterval(() => {
	  updateKunden(mutate);
	  updateProdukte(mutate);
    }, window.ACDSHOP_REFRESH_KUNDEN_INTERVAL);
	
    return () => clearInterval(interval);
  }, []);
  
  return (
    <React.StrictMode>
	  <ToastContainer position="top-center" autoClose={window.ACDSHOP_TOAST_DURATION} />
      <RouterProvider router={router} />
	</React.StrictMode>
  );	
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<App />);

serviceWorkerRegistration.register();